<template>
  <!-- 培训记录 -->
  <div class="trainingRecord">
    <van-nav-bar :title="$t('trainRecords.TrTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <van-search v-model="value"
                shape="round"
                :placeholder="$t('trainRecords.TrTxt2')"
                @search="onSearch" />
    <div class="overflow_div">
      <van-list v-model="loadingAlready"
                :finished="finishedAlready"
                :finished-text="$t('trainRecords.TrTxt3')"
                @load="onLoadAlready">
        <div class="cellGroup already_border"
             v-for="(item, index) in alreadyList"
             :key="index"
             @click="toDetails(item, index)">
          <van-cell class="first_cell"
                    :title="item.coursename"
                    :label="$t('trainRecords.TrTxt4')+item.teacher_empname">
            <template #right-icon>
              <img src="@/assets/img/trained.png"
                   alt="" />
            </template>
          </van-cell>
          <van-cell class="last_cell"
                    :title="$t('trainRecords.TrTxt5')+item.planbt"
                    :value="item.trainstyle" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { searchFunc } from '@/utils';
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { getHisTrainRecordList } from '@api/wxpx.js'
export default {
  name: "trainRecords",
  data () {
    return {
      userInfo,
      value: "",
      active: 0,
      isLoadingAwait: false,
      awaitList: [],
      loadingAwait: false,
      finishedAwait: false,
      isLoadingAlready: false,
      alreadyList: [],
      loadingAlready: false,
      finishedAlready: false,
      oldlist: [],
      form: {
        coursename: "",
        teacher_empname: ""
      },
    };
  },
  methods: {
    // 回退
    onClickLeft () {
      if (window.CloundEntid == '115') {
        window.location.href = 'http://hyclound.cn/hgvis_115_h5/pc2022_5.html'
      } else {
        this.$router.push('/home')
      }
    },
    onClickRight () { },
    onLoadAlready () {
      getHisTrainRecordList({
        empid: this.userInfo.empid
      }).then(res => {
        // console.log(res)
        this.alreadyList = res.data;
        this.oldlist = this.alreadyList;
        // 加载状态结束
        this.loadingAlready = false;
        this.finishedAlready = true;
      })
    },
    toDetails (itm) {
      this.$router.push({
        path: "/trainRecordsDetails",
        query: { autoid: itm.autoid },
      });
    },
    // 搜索
    onSearch () {
      var arr = searchFunc(this.value, this.form, this.oldlist);
      if (arr.length > 0)
      {
        this.alreadyList = arr;
        this.value = "";
      } else
      {
        Toast(this.$t('trainRecords.TrTxt6'));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.trainingRecord {
  height: 100vh;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 210px);
    // background: #fff;
    ::v-deep.van-tab {
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
    .await_border {
      border-left: 8px solid #ff8b00;
    }
    .already_border {
      border-left: 8px solid #2b8df0;
    }
    .cellGroup {
      margin: 20px 30px;
      border-radius: 8px;
      .first_cell {
        border-radius: 0 8px 0 0;
        padding: 0;
        padding-bottom: 20px;
        .van-cell__title {
          padding: 20px 30px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          .van-cell__label {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 48px;
            color: #666666;
          }
        }
        img {
          width: 126px;
          height: 126px;
        }
      }
      .last_cell::after {
        border-bottom: unset;
      }
      .last_cell {
        border-radius: 0 0 8px 0;
        .van-cell__title {
          flex: 2;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 48px;
          color: #666666;
        }
        .van-cell__value {
          flex: 1;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 48px;
          color: #999999;
        }
      }
    }
  }
}
</style>